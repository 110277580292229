@import 'fonts'

html,
body
  padding: 0
  margin: 0
  font-family: 'Poppins', sans-serif
  background: #FFF9F9
  scroll-behavior: smooth

a
  color: inherit
  text-decoration: none

*
  box-sizing: border-box

img
  max-width: 100%
  height: auto

h1, h2, h3, h4, h5
  font-family: 'Kabel', sans-serif
